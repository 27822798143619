import { IconType } from 'react-icons';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import { FiYoutube } from 'react-icons/fi';
import { FaFacebookF } from 'react-icons/fa6';
import { PiYoutubeLogoLight, PiTiktokLogoLight, PiInstagramLogoLight, PiFacebookLogoLight } from 'react-icons/pi';
import ReactGA from 'react-ga4';

const config = require('../config/web-site');
const { social } = config;

const setButtonIcon = (key: string) => {
  switch(key) {
    case 'instagram':
      return FaInstagram;
    case 'facebook':
      return FaFacebookF;
    case 'tiktok':
      return FaTiktok;
    default:
      return FiYoutube;
  }
}

const setFooterButtonIcon = (key: string) => {
  switch(key) {
    case 'instagram':
      return PiInstagramLogoLight;
    case 'facebook':
      return PiFacebookLogoLight;
    case 'tiktok':
      return PiTiktokLogoLight;
    default:
      return PiYoutubeLogoLight;
  }
}

type BuildSocialButtonType = (id: string, title: string, icon: IconType, testID?: string, handler?: React.MouseEventHandler<HTMLAnchorElement>) => JSX.Element;

export const setSocialButtons = (buildSocialButton: BuildSocialButtonType, source: string) => {
  const buttons = [];
  for(const media in social) {
    buttons.push(
      buildSocialButton(
        media, 
        social[media].title,
        source === 'home' ? setButtonIcon(media) : setFooterButtonIcon(media),
        `${media}-button`,
        handleWrapperClick
      )
    );
  }
  return buttons;
}

const handleClick = (
  event: React.MouseEvent<HTMLAnchorElement> | React.TouchEvent<HTMLAnchorElement>
) => {
  event.preventDefault();
  const style = event.currentTarget.getAttribute('class');
  for (const media in social) {
    if (style?.includes(media)) {
      ReactGA.event({
        category: config.analytics.parameters.categories.navigation,
        action: `${media} Click`,
        label: config.analytics.parameters.labels.click,
      });
      window.open(
        social[media as keyof typeof social].url,
        '_blank'
      );
    }
  }
};

const handleWrapperClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
  handleClick(event);
};