import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import Localization from './localization';

const rootElement = document.getElementById('root');

// const config = require('./config/web-site');

const root = ReactDOM.createRoot(
  rootElement as HTMLElement
);
  
Localization.setLanguage();

root.render(
  <React.StrictMode>
    { /*<App config={config} /> */}
    <App />
  </React.StrictMode>
);

reportWebVitals();