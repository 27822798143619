import React from 'react';
import { Nav } from 'react-bootstrap';
import DrumIcon from './DrumIcon';
import { IconType } from 'react-icons';
import { setSocialButtons } from '../utils/social-utils';

const buildSocialButton = (
  id: string, 
  title: string,
  icon: IconType,
  testID?: string,
  handler?: React.MouseEventHandler<HTMLAnchorElement>
) => {
  return (
    <DrumIcon key={ id } {...{
      id,
      title,
      Icon: icon,
      testID,
      handler
    }} />
  )
}

const DrumMenu: React.FC = () => {
	return (
    <Nav className='justify-content-center nav-home' role='navigation'>
      { setSocialButtons(buildSocialButton, 'home') }
    </Nav>
	);
}

export default DrumMenu;
