import React, { useState } from 'react';
import { Navbar, Nav, Button, Offcanvas, Container } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { RWebShare } from 'react-web-share';
import { FiShare } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import Localization from '../localization';
import { getVictoriaAge } from '../utils/utils';
import LangNavBar, { Orientation } from './LangNavBar';
import { ImArrowUp } from 'react-icons/im';

const conf = require('../config/web-site');

const strings = Localization.getStrings();

type NavBarProps = {
  scroller: (arg: string) => void;
  configuration?: typeof conf;
  className?: string;
  source?: string;
}



const renderShareButton = (config: typeof conf, mobile?: boolean) => {
  const description = strings.t_description.replace('{age}', Localization.translateNumber(getVictoriaAge()));

  return (
    <Nav.Item data-testid='share-button' className='justify-content-end'>
      <RWebShare
        closeText={strings.t_close}
        data={{
          text: `${config.title}\n${description.charAt(0).toUpperCase()}${description.slice(1)}`,
          url: config.url,
          title: config.title
        }}
        onClick={() => {
          ReactGA.event({
            category: config.analytics.parameters.categories.navigation,
            action: 'Share Button Click',
            label: config.analytics.parameters.click
          });
        }}
      >
        <Nav.Link>
          <FiShare size={21} className='icon' />
          {mobile && strings.b_share}
        </Nav.Link>
      </RWebShare>
    </Nav.Item>
  );
}

export const NavBar: React.FC<NavBarProps> = (props) => {
  const { scroller, configuration } = props;
  const config = configuration || conf;

  return (
    <div>
      <Navbar expand="lg">
        <Container>
          <Nav className='justify-content-center'>
            {
              Object.values(config.pages).map((page: typeof config.pages[0]) => page.route != 'home' && (
                <Nav.Link key={page.route} data-testid={`${page.route}-scroll-button`} className='nav-item' onClick={() => {
                  ReactGA.event({
                    category: config.analytics.parameters.categories.navigation,
                    action: `Click on ${page.title} menu button`,
                    label: config.analytics.parameters.labels.click
                  });
                  scroller(page.route);
                }}
                >
                  {strings[`b_${page.route}`]}
                </Nav.Link>
              ))
            }
          </Nav>
          <Nav className='justify-content-end'>
            {
              renderShareButton(config)
            }
            <LangNavBar orientation={Orientation.Right} />
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export const AltNavBar: React.FC<NavBarProps> = (props) => {
  const { scroller, configuration, className, source } = props;
  const config = configuration || conf;

  return (
    <div>
      <Navbar expand="lg">
        <Container>
          <Nav className='justify-content-center'>
            {
              <Nav.Link key='home' data-testid='scroll-top-button' className={`nav-item ${className ? className : ''}`} onClick={() => {
                ReactGA.event({
                  category: config.analytics.parameters.categories.navigation,
                  action: `Scroll to Top Button Click from ${source}`,
                  label: config.analytics.parameters.labels.click
                });
                scroller('home');
              }}
              >
                {`${strings.b_scroll_to_top} `}
                <ImArrowUp style={{ verticalAlign: 'text-top' }} />
              </Nav.Link>
            }
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export const MobileNavBar: React.FC<NavBarProps> = (props) => {
  const [show, setShow] = useState(false);

  const { scroller, configuration } = props;
  const config = configuration || conf;

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    ReactGA.event({
      category: config.analytics.parameters.categories.navigation,
      action: 'Mobile menu expanded',
      label: config.analytics.parameters.labels.tap
    });
  }

  return (
    <>
      <Navbar>
        <Container>
          <Button variant='link' onClick={handleShow} className="ms-auto" size='lg' style={{ color: 'white' }}>
            <GiHamburgerMenu />
          </Button>
        </Container>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement="end" className="offcanvas-fullscreen">
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            {
              Object.values(config.pages).map((page: typeof config.pages[0]) => (
                <Nav.Link key={page.route} data-testid={page.route} className='nav-mobile-item' onClick={() => {
                  ReactGA.event({
                    category: config.analytics.parameters.categories.navigation,
                    action: `Click on ${page.title} mobile menu button`,
                    label: config.analytics.parameters.labels.tap
                  });
                  handleClose();
                  scroller(page.route);
                }}
                >
                  {strings[`b_${page.route}`]}
                </Nav.Link>
              ))
            }
            {
              <LangNavBar orientation={Orientation.Left} />
            }
            {
              renderShareButton(config, true)
            }
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
