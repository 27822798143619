import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useScroll } from '../utils/scroll-utils';

import Home from './Home';
import About from './About';
import Soultone from './Soultone';
import Press from './Press';
import Shows from './Shows';
import Footer from './Footer';

import '../styles/App.scss';

import config from '../config/web-site.json';

const App: React.FC = () => {
  const [, setScrollingManually] = useState(true);
  const [hasMounted, setHasMounted] = useState(false);
  const [hideBackPicture, setBackPicture] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);
  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const isFirstRender = useRef(true);
  
  const onComponentVisible = (componentName: string, direction: string) => {
    let message = `${componentName} viewed`;
 
    if (isFirstRender.current) {
      isFirstRender.current = false;
      message += ' first render';
    } else {
      message += ` scrolling ${direction}`;
    }
    ReactGA.event({
      category: config.analytics.parameters.categories.navigation,
      action: message,
      label: config.analytics.parameters.labels.scroll
    });
  };

  const {
    handleVisibilityChange,
    scrollToPage,
    handleScroll,
  } = useScroll(containerRef, sectionRefs, onComponentVisible, setScrollingManually, hasMounted);

  const handleScrollAndTouchMove = () => { 
    handleScroll();

    const buffer = 100;
    if (!containerRef.current) return;
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;

    if(scrollTop <= 0 || scrollTop + clientHeight >= scrollHeight - buffer) {
      setBackPicture('hidden');
    } else {
      setBackPicture('');
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleVisibilityChange, {
      root: containerRef.current,
      threshold: 0.5,
    });

    sectionRefs.current.forEach((ref) => ref && observer.observe(ref));

    return () => observer.disconnect();
  }, [handleVisibilityChange]);

  useEffect(() => {
    setHasMounted(true);
    const container = containerRef.current;
  
    if (container) {
      container.addEventListener('scroll', handleScrollAndTouchMove, { passive: true });
      container.addEventListener('touchmove', handleScrollAndTouchMove, { passive: true });
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  ReactGA.initialize(
    config.analytics['tracking-id'], { 
      testMode: process.env.NODE_ENV != 'production' 
    }
  );

  const sections = [
    { id: 'home', component: <Home scroller={scrollToPage} /> },
    { id: 'about', component: <About scroller={scrollToPage} /> },
    { id: 'press', component: <Press scroller={scrollToPage} /> },
    { id: 'picture-window', component: <section id='picture-window' data-testid='picture-window-view' /> },
    { id: 'shows', component: <Shows scroller={scrollToPage} /> },
    { id: 'soultone', component: <Soultone scroller={scrollToPage} /> },
    { id: 'footer', component: <Footer /> },
  ];

  return (
    <main ref={containerRef}>
      <section id='main-section' data-testid='app-view'>
        <section id='picture' data-testid='picture-view' className={hideBackPicture} /> 
        {sections.map((section, index) => (
          <div
            key={section.id}
            id={section.id}
            ref={(el) => {
              if (el) sectionRefs.current[index] = el;
            }}
          >
            {section.component}
          </div>
        ))}
      </section>
    </main>
  );
};

export default App;
