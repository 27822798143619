import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { AltNavBar } from '../components/NavBar';
import Localization from '../localization';
import { formatDate } from '../utils/utils';
import ReactGA from 'react-ga4';

import conf from '../config/web-site.json';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/Carousel.scss';

const shows: (keyof typeof conf.shows)[] = Object.keys(conf.shows) as (keyof typeof conf.shows)[];
const strings = Localization.getStrings();

type ShowProps = {
  scroller: (arg: string) => void;
  config?: typeof conf;
}

const Shows: React.FC<ShowProps> = (props) => {
  const { scroller } = props;

  return (
    <section id="shows" className='carousel-section' data-testid='shows-view'>
    <AltNavBar {...{ scroller, source: conf.pages.shows.title }} />
    <h1>{strings['b_shows']}</h1>
    <Carousel 
      emulateTouch={true}
      onClickThumb={(e)=>{
        ReactGA.event({
          category: conf.analytics.parameters.categories.navigation,
          action: `Clicked on ${strings[`${shows[e]}_title`]} thumbnail`,
          label: conf.analytics.parameters.labels.click,
        });
      }} 
      onChange={(e)=>{
        ReactGA.event({
          category: conf.analytics.parameters.categories.navigation,
          action: `Navigated to ${strings[`${shows[e]}_title`]} show`,
          label: conf.analytics.parameters.labels.click,
        });
      }} 
    >
    
    {
      shows.map(show => (
      <div key={show}>
        <img src={require(`../assets/img/shows/${conf.shows[show].image}`)} alt={strings[`${show}_title`]} />
        <p className="legend">
          <strong>{strings[`${show}_title`]}</strong>
          <br />
          {strings[`${show}_description`]}
          <br />
         {formatDate(conf.shows[show].date)}
        </p>
      </div>
      ))
    }
    </Carousel>
    </section>
  );
};

export default Shows;