import React from 'react';
import { IconType } from 'react-icons';import { PiCopyrightLight } from 'react-icons/pi';
import { CiMail } from 'react-icons/ci';
import Localization from '../localization';
import ReactGA from 'react-ga4';
import { setSocialButtons } from '../utils/social-utils';

import '../styles/Footer.scss';
import logo from '../assets/img/logo.png';

const config = require('../config/web-site');
const { title, social, email } = config;

const strings = Localization.getStrings();

const buildSocialButton = (
  id: string, 
  title: string,
  Icon: IconType
) => {
  return (
    <a 
      key={id}
      href={social[id].url} 
      target='_blank' 
      rel='noopener noreferrer' 
      title={title}
      onClick={clickHandler}
    >
      <Icon />
    </a>
  );
}

const clickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
  ReactGA.event({
    category: config.analytics.parameters.categories.navigation,
    action: `${event.currentTarget.title} Click on Footer`,
    label: config.analytics.parameters.labels.click,
  });
}

const Footer: React.FC = () => {
    return (
        <footer>
            <img src={logo} alt={config.title} className='footer-logo' data-testid='footer-view' />
            <p className='footer-social-links'>
              <a href={`mailto:${email}`} target='_blank' rel='noopener noreferrer' title='Mail' onClick={clickHandler}>
                <CiMail />
              </a>
              {setSocialButtons(buildSocialButton, 'footer')}
            </p>
            <p><PiCopyrightLight />{` ${new Date().getFullYear()} ${title}. ${strings.t_footer}.`}</p>
        </footer>
    );
}

export default Footer;