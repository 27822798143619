import { Language } from './language';

const zh = {
    code: Language.zh,

	// HOME PAGE
	
	t_description: '女鼓手，{age}岁',
	t_close: '关闭',
	
	b_home: '首页',
	b_about: '关于我',
	b_press: '按',
	b_shows: '演出',
	b_soultone: '灵魂音',
	b_language: '中国人',
	b_share: '分享',
	b_scroll_to_top: '滚动至顶部',

	t_english: '英语',
	t_spanish: '西班牙语',
	t_portuguese: '葡萄牙语',
	t_italian: '意大利语',
	t_french: '法语',
	t_german: '德语',
	t_chinese: '中国人',

	// SHOWS PAGE

	StAlsGrandDay2024_title: '2024 年盛大日演出',
	StAlsGrandDay2024_description: '圣阿方索斯教区学校 西雅图',

	BlackViolinConcert_title: '与世界你的乐团的黑色小提琴音乐会',
	BlackViolinConcert_description: '派拉蒙剧院',

	MexicanIndependenceDay2024_title: '巴拉德市民管弦乐团庆祝墨西哥独立日',
	MexicanIndependenceDay2024_description: '西雅图市政厅',

	BluesRock_title: '蓝调摇滚',
	BluesRock_description: '西雅图摇滚学校',

	BallardSeaFoodFest2024_title: '二零二四 巴拉德海鲜节',
	BallardSeaFoodFest2024_description: '巴拉德海鲜节',

	Funk_title: '放克',
	Funk_description: '西雅图摇滚学校',

	Heart_title: '心',
	Heart_description: '西雅图摇滚学校',

	ModernMetal_title: '现代金属',
	ModernMetal_description: '西雅图摇滚学校',

	SeasonPreviewWinter2023_title: '二零二三 年冬季季节预览',
	SeasonPreviewWinter2023_description: '西雅图摇滚学校',

	SmashingPumpkins_title: '粉碎南瓜',
	SmashingPumpkins_description: '西雅图摇滚学校',

	Nirvana_title: '涅槃',
	Nirvana_description: '西雅图摇滚学校',

	HairMetal_title: '头发金属',
	HairMetal_description: '西雅图摇滚学校',

	TalentShow2023_title: '二零二三才艺展示',
	TalentShow2023_description: '西雅图鲑鱼湾小学',

	Deftones_title: '去色调',
	Deftones_description: '西雅图摇滚学校',

	SeasonPreviewSpring2023_title: '二零二三 年春季季前瞻',
	SeasonPreviewSpring2023_description: '西雅图摇滚学校',

	Ramones_title: '雷蒙斯',
	Ramones_description: '西雅图摇滚学校',

	Soundtrack_title: '配乐秀',
	Soundtrack_description: '西雅图摇滚学校',

	PopPunkVsEmo_title: '流行朋克与。 情绪',
	PopPunkVsEmo_description: '西雅图摇滚学校',

	_90sNostalgia_title: '九零年代怀旧',
	_90sNostalgia_description: '西雅图摇滚学校',

	TalentShow2022_title: '二零二二才艺展示',
	TalentShow2022_description: '西雅图鲑鱼湾小学',

	// PRESS PAGE

	b_watch_here: '在这里观看',

	the_daly_migs_show_interview_title: '达利米奇秀采访',
	the_daly_migs_show_interview_description: '关于受邀加入 Godsmack 舞台的采访',

	alizee_defan_07_02_2024_title: '《艾莉婕·德凡》专访',
	alizee_defan_07_02_2024_description: '独家专访',

	jaime_mendez_news_06_26_2024_title: '《海梅·门德斯新闻》采访',
	jaime_mendez_news_06_26_2024_description: '西雅图当地西班牙语新闻',

	bien_informado_03_22_2023_title: '“消息灵通" 文章',
	bien_informado_03_22_2023_description: '阿根廷新闻网站关于维多利亚鼓的文章',

	wave_potter_reaction_01_30_2023_title: '波波特 反应',
	wave_potter_reaction_01_30_2023_description: '波波特 对 胜利 的 永恒之光 封面做出反应',

	// ABOUT PAGE

	b_back: '回去',

	t_title: 'Victoria Acosta',
	t_pronouns: '她',

	b_read_more: '阅读更多',
	b_read_less: '阅读较少',

	t_about: `
认识Victoria Acosta，这位充满活力的{age}岁鼓手，她那充满激情的精神和对音乐的不懈热爱正征服着全世界！Victoria出生于德克萨斯州的埃尔帕索，拥有墨西哥Ciudad Juárez的根基，并在墨西哥的瓜达拉哈拉街头度过了童年。如今，她居住在华盛顿州的西雅图，并继续以她非凡的才华和对艺术的投入激励着观众。

Victoria对鼓的兴趣始于四岁。当大多数孩子还在玩玩具时，这位卓越的天才已经开始敲打节奏，并向她的家人宣布鼓是她最喜欢的乐器。

2018年9月13日，她的梦想成真，收到了她的第一套鼓，并从那时起，她就再也没有停止过！她在瓜达拉哈拉的著名音乐学院——Musicalerias Academy报名，开始正式学习，像真正的摇滚明星一样掌握节奏的艺术。

2019年10月10日，Victoria的冒险进入了新阶段，她的家人搬到了西雅图。她毫不动摇地继续在线学习，证明了距离对于一个充满激情的音乐家来说并不是障碍。她的重大突破出现在2022年5月29日，当时她在传奇的摇滚学校（School of Rock）进行了试听课。虽然她的大多数同龄人都开始参加初学者的Rock 101课程，但Victoria的天赋脱颖而出，她成功地进入了备受瞩目的高级表演者课程（Performers Program）。从那时起，她在{shows}的现场演出中以其感染力十足的能量和迷人的舞台表现，征服了观众。

但这仅仅是开始！Victoria不仅是一个演出者，她还是一个社交媒体现象，已经录制了数百个展示她鼓技的视频，吸引了大量关注。2024年6月13日，她作为客座鼓手参加了意大利作曲家Alberto Rigonni的《Song Symposium》专辑《Nemesis Call》的演出，标志着她在音乐界的崭露头角！

在一次令人兴奋的里程碑中，Victoria于2024年9月12日与Soultone Cymbals签订了代言协议，巩固了她在鼓手精英中的地位，并将她的音色提升到新的高度！

为了进一步扩展她的音乐视野，Victoria在四年级时开始学习小提琴。2024年5月13日，她加入了世界青年管弦乐团，并有幸与著名的Black Violin二重奏一起在Paramount剧院演出，凭借她非凡的才华打动了2000人的观众。

她的音乐之旅并未就此止步！2024年8月29日，她作为打击乐手加入了Ballard Civic Orchestra，并在西雅图的Town Hall举行了墨西哥独立日庆祝活动，面对近1000名热情的观众进行了首次亮相。

Victoria的成就和她的演出一样令人印象深刻。她在2022年的伦敦青年音乐家比赛中获得了金奖和优秀技术奖，并在2023年获得了伦敦青年音乐家年度明星音乐家奖和年度杰出鼓手特别奖。

现在，Victoria已经扎根于西雅图，每天都在练习，不断突破自己在鼓和小提琴方面的技艺。她的眼光投向了音乐的光辉未来，这位充满活力的年轻艺术家才刚刚起步。准备好被Victoria Acosta激励吧——在这里，节奏与激情交织，梦想成真！
`,

	// PRESS PAGE

	t_footer: '版权所有',

	// SOULTONE: 

	b_visit_artist_page: '访问我的艺术家页面',

}

export default zh;
