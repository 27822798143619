import React from 'react';

import drumIcon from '../assets/img/drum-icon.png'; // Ensure this path is correct
import { Nav, Image } from 'react-bootstrap';
import { IconType } from 'react-icons/lib';

type DrumIconProps = {
	id: string,
	Icon: IconType,
	title: string,
	testID?: string,
	handler?: React.MouseEventHandler<HTMLElement>
}

const DrumIcon: React.FC<DrumIconProps> = (props) => {
	const { id, Icon, title, testID, handler } = props;

	return (
		<Nav.Link 
			className={ `image-link ${id}`} 
			data-testid={ testID || `drum-icon-${id}`} 
			onClick={ handler }
		>
			<div className="image-container">
				<Image src={ drumIcon } alt={ title } className='drum-icon image' />
				<Icon className='drum-icon-logo' />
			</div>
		</Nav.Link>
	);
}

export default DrumIcon;
