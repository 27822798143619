import React from 'react';
import { DateTime } from 'luxon';
import Localization from '../localization';

const config = require('../config/web-site');

export const getAge = (birthday: number) => {
	const diff = Date.now() - birthday;
	const date = new Date(diff);
	return Math.abs(date.getUTCFullYear() - 1970);
}

export const getVictoriaAge = () => {
	const birthday = Date.parse(config.birthday);
	return getAge(birthday);
}

export const processParagraphs = (text: string) => {
	let count = 0;
	const paragraphs = text.split('\n').map((p) => {
		return (<p key={`paragraph-${++count}`}>{p}</p>)
	});
	return paragraphs;
}

export const formatDate = (date: string) => {
	return DateTime.fromISO(
		date, {
		  locale: Localization.getLanguage()
		}).toLocaleString({
		  year: 'numeric', 
		  month: 'long', 
		  day: 'numeric'
		});
}