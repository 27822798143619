import { useState, useEffect } from 'react';

export enum Orientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

// Define the custom hook with TypeScript
export const useDeviceOrientation = (): Orientation => {
  const [orientation, setOrientation] = useState<Orientation>(
    window.innerWidth > window.innerHeight ? Orientation.Landscape : Orientation.Portrait
  );

  useEffect(() => {
    const handleResize = () => {
      setOrientation(window.innerWidth > window.innerHeight ? Orientation.Landscape : Orientation.Portrait);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return orientation;
};