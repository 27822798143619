import React from 'react';
import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MobileNavBar, NavBar } from '../components/NavBar';
import DrumMenu from '../components/HomeNavBar';

import { Orientation, useDeviceOrientation } from '../hooks/device-orientation';

import logo from '../assets/img/logo.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Home.scss';

const conf = require('../config/web-site');
const minWidthForDesktopMenu = 992;

type HomeProps = {
  scroller: (arg: string) => void;
}

const Home: React.FC<HomeProps> = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { scroller } = props;
  const orientation = useDeviceOrientation();

  return (
    <section id='home' data-testid='home-view'>
      <section className='nav-main'>
      { orientation === Orientation.Portrait || width < minWidthForDesktopMenu
        ? <MobileNavBar {...{ scroller }} />
        : <NavBar {...{ scroller }} />
      }
      </section>
      <Container fluid>
      <Row>
        <Col>
          <img src={logo} alt={conf.title} className='logo' />
        </Col>
      </Row>
      <Row>
        <Col>
          <DrumMenu />
        </Col>
      </Row>
      </Container>
    </section>
  );
}

export default Home;