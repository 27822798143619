import React from 'react';
import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Localization from '../localization';
import { AltNavBar } from '../components/NavBar';
import { getVictoriaAge, processParagraphs } from '../utils/utils';
import { TbLayoutNavbarCollapseFilled, TbLayoutBottombarCollapseFilled } from 'react-icons/tb';

import conf from '../config/web-site.json';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/About.scss';

type Shows = {
  [key: string]: {
    location: string;
    date: string;
  };
}

const strings = Localization.getStrings();

const filterSorShows = (shows: Shows) => {
  return Object.entries(shows).filter(show =>
    show[1].location === 'SOR'
  ).length;
}

type AboutProps = {
  scroller: (arg: string) => void;
  config?: typeof conf;
}

const About: React.FC<AboutProps> = (props) => {
  const config = props.config || conf;
  const { scroller } = props;

  const [, setWidth] = useState(window.innerWidth);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const shows = filterSorShows(config.shows);

  const readMoreHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setExpanded(!expanded);
    if(expanded) props.scroller('about');
  }

  const paragraphs = processParagraphs(
    strings.t_about
    .replace('{age}', Localization.translateNumber(getVictoriaAge()))
    .replace('{shows}', Localization.translateNumber(shows))
  ); 

  return (
    <section id="about">
    <AltNavBar {...{ scroller, source: config.pages.about.title }} />
      <Container>
        <Row>
            <Col data-testid='about-view'>
                <h1>{strings.t_title}</h1>
                <div className='about-text'>
                { expanded ? paragraphs : paragraphs.slice(0, 8) }
                <a href="#" onClick={readMoreHandler}>
                  { expanded ? (
                    <>
                      {`${strings.b_read_less} `}
                      <TbLayoutNavbarCollapseFilled style={{ verticalAlign: 'text-top' }} />
                    </>
                  ) : (
                    <>
                      {`${strings.b_read_more} `}
                      <TbLayoutBottombarCollapseFilled style={{ verticalAlign: 'text-top' }} />
                    </>
                  )}
                  
                </a>
                </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;
