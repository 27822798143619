import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { AltNavBar } from '../components/NavBar';
import Localization from '../localization';
import { formatDate } from '../utils/utils';
import ReactGA from 'react-ga4';

import conf from '../config/web-site.json';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/Carousel.scss';

const press: (keyof typeof conf.press)[] = Object.keys(conf.press) as (keyof typeof conf.press)[];
const strings = Localization.getStrings();

type PressProps = {
  scroller: (arg: string) => void;
  config?: typeof conf;
}

const Press: React.FC<PressProps> = (props) => {
  const { scroller } = props;

  return (
    <section id="press" className='carousel-section' data-testid='press-view'>
    <AltNavBar {...{ scroller, source: conf.pages.press.title }} />
    <h1>{strings['b_press']}</h1>
    <Carousel 
      emulateTouch={true} 
      onClickThumb={(e)=>{
        ReactGA.event({
          category: conf.analytics.parameters.categories.navigation,
          action: `Clicked on ${strings[`${press[e]}_title`]} thumbnail`,
          label: conf.analytics.parameters.labels.click,
        });
      }} 
      onChange={(e)=>{
        ReactGA.event({
          category: conf.analytics.parameters.categories.navigation,
          action: `Navigated to ${strings[`${press[e]}_title`]} article`,
          label: conf.analytics.parameters.labels.click,
        });
      }} 
    >
    {
      press.map(press => (
      <div key={press}>
        <img src={require(`../assets/img/press/${conf.press[press].image}`)} alt={strings[`${press}_title`]} />
        <p className='legend'>
          <strong>{strings[`${press}_title`]}</strong>
          <br />
          {strings[`${press}_description`]}
          <br />
         {formatDate(conf.press[press].date)}
         <br />
          <a href={conf.press[press].url} target='_blank' rel='noopener noreferrer' onClick={
            (event) => {
              ReactGA.event({
                category: conf.analytics.parameters.categories.navigation,
                action: `${event.currentTarget.title} Navigated to ${strings[`${press}_title`]}`,
                label: conf.analytics.parameters.labels.click,
              });
            }
          }>
            {strings.b_watch_here}
            <FaExternalLinkAlt style={{ verticalAlign: 'text-top' }}/>
          </a>
        </p>
      </div>
      ))
    }
    </Carousel>
    </section>
  );
};

export default Press;