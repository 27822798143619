import { RefObject, useRef, useCallback, useEffect } from 'react';

type ScrollDirection = 'up' | 'down';

export const useScroll = (
  containerRef: RefObject<HTMLDivElement>,
  sectionRefs: RefObject<HTMLDivElement[]>,
  onComponentVisible: (componentName: string, direction: ScrollDirection) => void,
  setScrollingManually: (value: boolean) => void,
  hasMounted: boolean
) => {
  const currentVisibleSection = useRef<string | null>(null);
  const skipNextVisibility = useRef(false);
  const lastScrollPosition = useRef<number>(0);
  const lastScrollDirection = useRef<ScrollDirection>('down');
  const isFirstRender = useRef(true);

  const handleVisibilityChange = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (sectionRefs.current && sectionRefs.current.length === 0) {
        return;
      }
      if (sectionRefs.current && sectionRefs.current.length > 0 && entries.length > 0) {
        const intersectingEntry = entries.find(entry => entry.isIntersecting);
        if (intersectingEntry) {
          const sectionId = intersectingEntry.target.id;
          if (!skipNextVisibility.current && hasMounted) {
            if (currentVisibleSection.current !== sectionId) {
              currentVisibleSection.current = sectionId;
              onComponentVisible(sectionId, lastScrollDirection.current);
            }
          }
        }
      }
    },
    [hasMounted, onComponentVisible]
  );

  const scrollToPage = (pageId: string) => {
    if (!containerRef.current) return;

    setScrollingManually(false);
    skipNextVisibility.current = true;

    const targetElement = document.getElementById(pageId);
    if (!targetElement) return;

    const startPosition = containerRef.current.scrollTop;
    const targetPosition = targetElement.offsetTop;
    const distance = targetPosition - startPosition;
    const duration = 1000;
    let startTime: number | null = null;

    const animateScroll = (currentTime: number) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);

      if (containerRef.current) {
        containerRef.current.scrollTop = startPosition + distance * easeInOutQuad(progress);
      }

      if (timeElapsed < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        setScrollingManually(true);
        skipNextVisibility.current = false;
      }
    };

    const easeInOutQuad = (t: number) =>
      t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;

    requestAnimationFrame(animateScroll);
  };

  const handleScroll = () => {
    if (!containerRef.current) return;

    const currentScrollPosition = containerRef.current.scrollTop;
    const direction = currentScrollPosition > lastScrollPosition.current ? 'down' : 'up';

    lastScrollPosition.current = currentScrollPosition;
    lastScrollDirection.current = direction;

    setScrollingManually(true);

    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, []);

  return {
    handleVisibilityChange,
    scrollToPage,
    handleScroll,
    sectionRefs,
    currentVisibleSection,
    lastScrollDirection,
    skipNextVisibility,
    isFirstRender,
  };
};
